import axios from 'axios';
import qs from 'qs';
import loadGoogleMapsApi from 'load-google-maps-api';

export default {
    
    replaceQueryInUrl(context, {url, params})
    {  
        window.history.replaceState(null, null, url + '?' + Object.keys(params).map(key => key + '=' + params[key]).join('&'));
    },

    loadGoogleMaps(context)
    {
        return new Promise((resolve, reject) => {
            console.log({google: context.state.google});
            if ( context.state.google ) {
                resolve();
            }

            loadGoogleMapsApi({
                key: window.google_maps_key,
                libraries: ['places'],
                language: context.state.locale.split('-').pop()
            }).then((google) => {
                context.commit('setGoogle', google);
                resolve();
            });
        });
    },

    formatLocation(context, { position, address }) {
        return new Promise(function(resolve, reject) {
            let location = {
                display: '',
                country: '',
                province: '',
                city: '',
                postal_code: '',
                street_name: '',
                street_number: '',
                latitude: '',
                longitude: ''
            };

            if (address.administrative_area_level_1) {
                location.province = address.administrative_area_level_1;
            }
            if (address.country) {
                location.country = address.country;
            }
            if (address.locality) {
                location.city = address.locality;
            }
            if (address.postal_code) {
                location.postal_code = address.postal_code;
            }
            if (address.route) {
                location.street_name = address.route;
            }
            if (address.street_number) {
                location.street_number = address.street_number;
            }
            if (address.latitude) {
                location.latitude = address.latitude;
            }
            if (address.longitude) {
                location.longitude = address.longitude;
            }

            if (position) {
                location.latitude = position.coords.latitude;
                location.longitude = position.coords.longitude;
            }
            resolve(location);
        });
    },

    getData(context, {url, urlParams = null, key = null, data = {}, method = 'GET', extras = {}})
    {
        if ( urlParams ) {
            url = url + '?' + Object.keys(urlParams).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(urlParams[k])}`).join('&');
        }

        return new Promise(function(resolve, reject) {
            axios({
                method: method,
                url: url,
                data: data
            })
            .then((response) => {
                if ( ! key ) {
                    return resolve(response.data);
                }
                resolve(response.data[key]);
            })
            .catch((error) => {
                console.error('An error occured while making a request.', {error: error, url: url, method: method, data: data});
                reject(error);
            });
        });
    },

    postData(context, {url, urlParams = null, key, data = {}, method = 'POST', extras = {}})
    {
        if ( urlParams ) {
            url = url + '?' + Object.keys(urlParams).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(urlParams[k])}`).join('&');
        }

        return new Promise(function(resolve, reject) {
            axios({
                method: method,
                url: url,
                data: data
            })
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                console.error('An error occured while making a request.', {error: error, url: url, method: method, data: data});
                reject(error);
            });
        });
    },

    updateData(context, {url, urlParams = null, key, data = {}, method = 'PUT', extras = {}})
    {
        if ( urlParams ) {
            url = url + '?' + Object.keys(urlParams).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(urlParams[k])}`).join('&');
        }

        return new Promise(function(resolve, reject) {
            axios({
                method: method,
                url: url,
                data: data
            })
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                console.error('An error occured while making a request.', {error: error, url: url, method: method, data: data});
                reject(error);
            });
        });
    },

    ping(context, {url, method = 'GET'})
    {
        return new Promise(function (resolve, reject) {
            axios({
                method: method,
                url: url
            })
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                console.error('An error occured while trying to ping the url.', {error: error, url: url, method: method});
                reject(error);
            });
        });
    },

    deleteData(context, {url, itemToDelete = null, array = null, key = 'id'})
    {
        return new Promise(function (resolve, reject) {
            axios({
                method: 'DELETE',
                url: url
            })
            .then((response) => {
                if ( array && key && itemToDelete ) {
                    context.dispatch('deleteItemInArray', {array: array, key: key, itemToDelete: itemToDelete});
                }
                resolve(response);
            })
            .catch((error) => {
                console.error('An error occured while trying to delete data.', {error: error, url: url, array: array, key: key});
                reject(error);
            });
        });
    },

    deleteItemInArray(context, {array, key, itemToDelete})
    {
        for ( let i = 0; i < array.length; i++ ) {
            if ( array[i][key] == itemToDelete ) {
                array.splice(i, 1);
            }
        }
    },

    setVariableInTitle(context, {variable, value})
    {
        if ( document.title.includes('{'+variable+'}') ) {
            document.title = document.title.replace('{'+variable+'}', value);
        };
    },

    makeAccountRequest(context, {url, urlParams = {}, method = 'GET', data = {}})
    {
        if ( data instanceof FormData ) {

            for ( let pair of data.entries() ) {
                if ( pair[1] == 'null' ) {
                    data.set(pair[0], '');
                }
            }

            if ( method == 'PUT' ) {
                method = 'POST';
                data.append('_method', 'PUT');
            }

        }
        

        return axios({
            method: method,
            params: urlParams,
            paramsSerializer: function(params) {
                return qs.stringify(params, { strictNullHandling: true });
            },
            url: url,
            data: data,
            headers: context.getters.accountApiHeaders
        })
        .then(response => response.data)
        .catch(error => { throw error.response.data; });
    },

    getAccountData(context, {url, urlParams = {}}) {
        return context.dispatch('makeAccountRequest', {url, method: 'GET', urlParams});
    },

    postAccountData(context, {url, data, urlParams = {}}) {
        return context.dispatch('makeAccountRequest', {url, data, method: 'POST', urlParams});
    },

    updateAccountData(context, {url, data, urlParams = {}}) {
        return context.dispatch('makeAccountRequest', {url, data, method: 'PUT', urlParams});
    },

    deleteAccountData(context, {url, urlParams = {}}) {
        return context.dispatch('makeAccountRequest', {url, method: 'DELETE', urlParams});
    },
}