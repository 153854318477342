<template>
    <div class="flex flex-wrap items-stretch mb-16 -mx-2">
        <div class="w-full px-2 md:w-1/2 lg:w-1/4">
            <profitability-document-total-panel :amount="totalQuoted">
                {{ titleQuoted }}
                <div v-if="displayLinks" slot="under-amount" class="mt-2">
                    <a href="#" class="text-sm hover:underline hover:text-black" @click.prevent="$bus.$emit('worksite:worksite-profitability-document-form-modal:open', {worksite: $store.state.worksite, type: 'quote'})">
                        <i class="mr-1 far fa-plus" /> {{ $t('worksite.profitability.panels.quoted.link') }}
                    </a>
                </div>
            </profitability-document-total-panel>
        </div>
        <div class="w-full px-2 md:w-1/2 lg:w-1/4">
            <profitability-document-total-panel :amount="totalCosts">
                {{ titleCosts }}
                <div v-if="displayLinks" slot="under-amount" class="mt-2">
                    <a href="#" class="text-sm hover:underline hover:text-black" @click.prevent="$bus.$emit('worksite:worksite-profitability-document-form-modal:open', {worksite: $store.state.worksite, type: 'cost'})">
                        <i class="mr-1 far fa-plus" /> {{ $t('worksite.profitability.panels.costs.link') }}
                    </a>
                </div>
            </profitability-document-total-panel>
        </div>
        <div class="w-full px-2 md:w-1/2 lg:w-1/4">
            <profitability-document-total-panel :amount="totalLiquidity" :display-icon="true" :bg-color="positiveLiquidity ? 'bg-[#77BB5A]' : 'bg-[#D95C4D]'" text-color="text-white">
                {{ titleLiquidity }}
                <span slot="under-amount" class="block mt-3 text-sm font-thin">{{ $t('worksite.profitability.panels.liquidity.text') }}</span>
            </profitability-document-total-panel>
        </div>
        <div class="w-full px-2 md:w-1/2 lg:w-1/4">
            <profitability-document-total-panel :amount="totalMargin" :display-icon="true" :bg-color="positiveMargin ? 'bg-[#77BB5A]' : 'bg-[#D95C4D]'" text-color="text-white">
                {{ titleMargin }}
                <span slot="under-amount" class="block mt-3 text-sm font-thin">{{ $t('worksite.profitability.panels.margin.text') }}</span>
            </profitability-document-total-panel>
        </div>
    </div>
</template>

<script>
    import DocumentTotalPanel from './DocumentTotalPanel';
    
    export default {
        components: {
            'profitability-document-total-panel': DocumentTotalPanel
        },
        props: {
            displayLinks: {
                type: Boolean,
                default: true
            },
            totalQuoted: {
                type: Number,
                default: 0
            },
            titleQuoted: {
                type: String,
            },
            totalCosts: {
                type: Number,
                default: 0
            },
            titleCosts: {
                type: String,
            },
            positiveLiquidity: {
                type: Boolean,
                default: false
            },
            totalLiquidity: {
                type: Number,
                default: 0
            },
            titleLiquidity: {
                type: String,
            },
            positiveMargin: {
                type: Boolean,
                default: false
            },
            totalMargin: {
                type: Number,
                default: 0
            },
            titleMargin: {
                type: String,
            },

        }
    }
</script>
