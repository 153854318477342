import Redirector from './components/Redirector';

import AccountsIndex from "./components/Accounts/Index";
import AccountsBase from "./components/Accounts/Base";

import OpportunitiesBase from "~base/components/Opportunities/Base";
import OpportunitiesIndex from "~base/components/Opportunities/Index";
import OpportunitiesArchives from "~base/components/Opportunities/Archives";

import OpportunityBase from "~base/components/Opportunity/Base";
import OpportunityShow from "~base/components/Opportunity/Show";

import WorksitesBase from "~base/components/Worksites/Base";
import WorksitesIndex from "~base/components/Worksites/Index";
import WorksitesArchives from "~base/components/Worksites/Archives";

import WorksiteBase from "~base/components/Worksite/Base";
import WorksiteInformations from "~base/components/Worksite/Informations";
import WorksiteProfitability from "~base/components/Worksite/Profitability";
import WorksiteFiles from "~base/components/Worksite/Files";
import WorksiteTables from "~base/components/Worksite/Tables";
import WorksitePhases from "~base/components/Worksite/Phases";
import WorksiteTimeSheets from "~base/components/Worksite/TimeSheets";
import WorksiteResources from "~base/components/Worksite/Resources";
import WorksiteProgressBillingsIndex from "~base/components/Worksite/ProgressBillings/Index";
import WorksiteProgressBillingsCreate from "~base/components/Worksite/ProgressBillings/Create";
import WorksiteProgressBillingsEdit from "~base/components/Worksite/ProgressBillings/Edit";

import PlanningBase from "~base/components/Planning/Base";
import PlanningWorksites from "~base/components/Planning/Worksites";
import PlanningUsers from "~base/components/Planning/Users";

import ProfitabilityBase from "~base/components/Profitability/Base";
import ProfitabilityIndex from "~base/components/Profitability/Index";

import RouteGenerator from "@deegital/trustup-spa-js-assets/routing/routes"
const generator = new RouteGenerator();

const routes = [

    generator.create({
        path: '/',
        name: 'homepage',
        redirect: {
            name: 'accounts.index'
        }
    }).public().get(),

    generator.create({
        path: '/redirection',
        name: 'redirection',
        component: Redirector
    }).protected().get(),
    
    generator.create({
        component: AccountsIndex,
    }).accounts().get(),

    generator.create({
        component: AccountsBase,
        redirect: {
            name: 'account.homepage'
        },
        children: [

            generator.create({
                path: 'home',
                name: 'account.homepage',
                redirect: {
                    name: 'account.worksites.index'
                }
            }).public().get(),

            generator.create({
                path: 'opportunities',
                name: 'account.opportunities.homepage',
                component: OpportunitiesBase,
                redirect: {
                    name: 'account.opportunities.index',
                },
                children: [
            
                    generator.create({
                        path: 'index',
                        name: 'account.opportunities.index',
                        component: OpportunitiesIndex,
                    }).layout({container: false}).sidebar('opportunities').protected().get(),
                    
                    generator.create({
                        path: 'archives',
                        name: 'account.opportunities.archives',
                        component: OpportunitiesArchives,
                    }).layout({container: false}).sidebar('opportunities').protected().get(),

                ]
            }).layout({container: false}).sidebar('opportunities').protected().get(),

            generator.create({
                path: 'opportunities/:opportunity',
                name: 'account.opportunity.homepage',
                component: OpportunityBase,
                redirect: {
                    name: 'account.opportunity.show',
                },
                children: [
                  
                    generator.create({
                        path: 'show',
                        name: 'account.opportunity.show',
                        component: OpportunityShow,
                    }).layout({container: false}).sidebar('opportunities').protected().get(),
                  
                    generator.create({
                        path: 'messages',
                        name: 'account.opportunity.messages',
                        component: OpportunityShow,
                    }).layout({container: false}).sidebar('opportunities').protected().get()

                ]
            }).layout({container: false}).sidebar('opportunities').protected().get(),
            
            generator.create({
                path: 'worksites',
                name: 'account.worksites.homepage',
                component: WorksitesBase,
                redirect: {
                    name: 'account.worksites.index',
                },
                children: [
                    generator.create({
                        path: 'index',
                        name: 'account.worksites.index',
                        component: WorksitesIndex,
                    }).layout({container: false}).sidebar('worksites').protected().get(),
                    
                    generator.create({
                        path: 'archived',
                        name: 'account.worksites.archives',
                        component: WorksitesArchives,
                    }).layout({container: false}).sidebar('worksites').protected().get(),
                ]
            }).layout({container: false}).sidebar('worksites').protected().get(),

            generator.create({
                path: 'worksites/:worksite',
                name: 'account.worksite.homepage',
                component: WorksiteBase,
                redirect: {
                    name: 'account.worksite.informations',
                },
                children: [
            
                    generator.create({
                        path: 'informations',
                        name: 'account.worksite.informations',
                        component: WorksiteInformations,
                    }).layout({container: false}).sidebar('worksites').protected().get(),

                    generator.create({
                        path: 'profitability',
                        name: 'account.worksite.profitability',
                        component: WorksiteProfitability,
                    }).layout({container: false}).sidebar('worksites').protected().get(),

                    generator.create({
                        path: 'files',
                        name: 'account.worksite.files',
                        component: WorksiteFiles,
                    }) .layout({container: false}).sidebar('worksites').protected().get(),

                    generator.create({
                        path: 'files/folder/:folder',
                        name: 'account.worksite.files.folder',
                        component: WorksiteFiles,
                    }) .layout({container: false}).sidebar('worksites').protected().get(),

                    generator.create({
                        path: 'phases',
                        name: 'account.worksite.phases',
                        component: WorksitePhases,
                    }) .layout({container: false}).sidebar('worksites').protected().get(),

                    generator.create({
                        path: 'tables',
                        name: 'account.worksite.tables',
                        component: WorksiteTables,
                    }) .layout({container: false}).sidebar('worksites').protected().get(),

                    generator.create({
                        path: 'time-sheets',
                        name: 'account.worksite.time-sheets',
                        component: WorksiteTimeSheets,
                    }) .layout({container: false}).sidebar('worksites').protected().get(),

                    generator.create({
                        path: 'resources',
                        name: 'account.worksite.resources',
                        component: WorksiteResources,
                    }) .layout({container: false}).sidebar('worksites').protected().get(),

                    generator.create({
                        path: 'progress-billings',
                        name: 'account.worksite.progress-billings.index',
                        component: WorksiteProgressBillingsIndex,
                    }) .layout({container: false}).sidebar('worksites').protected().get(),
                    generator.create({
                        path: 'progress-billings/create',
                        name: 'account.worksite.progress-billings.create',
                        component: WorksiteProgressBillingsCreate,
                    }) .layout({container: false}).sidebar('worksites').protected().get(),
                    generator.create({
                        path: 'progress-billings/:billing/edit',
                        name: 'account.worksite.progress-billings.edit',
                        component: WorksiteProgressBillingsEdit,
                    }) .layout({container: false}).sidebar('worksites').protected().get(),
                ]

            }).sidebar('worksites').protected().get(),

            generator.create({
                path: 'planning',
                name: 'account.planning.homepage',
                component: PlanningBase,
                redirect: {
                    name: 'account.planning.worksites',
                },
                children: [
            
                    generator.create({
                        path: 'worksites',
                        name: 'account.planning.worksites',
                        component: PlanningWorksites,
                    }).layout({container: false}).sidebar('planning').protected().get(),
                    
                    generator.create({
                        path: 'users',
                        name: 'account.planning.users',
                        component: PlanningUsers,
                    }).layout({container: false}).sidebar('planning').protected().get(),

                ]
            }).layout({container: false}).sidebar('planning').protected().get(),

            generator.create({
                path: 'profitability',
                name: 'account.profitability.homepage',
                component: ProfitabilityBase,
                redirect: {
                    name: 'account.profitability.index',
                },
                children: [
            
                    generator.create({
                        path: 'index',
                        name: 'account.profitability.index',
                        component: ProfitabilityIndex,
                    }).layout({container: false}).sidebar('profitability').protected().get(),

                ]
            }).layout({container: false}).sidebar('profitability').protected().get(),

        ]
    }).protected().account().title('Chantiers - Worksite').get(),

];

export default routes;