<template>
    <div class="h-full">
        <div class="relative py-12" v-if="loading"><v-spa-loader></v-spa-loader></div>
        <template v-if="! loading">
            
            <template v-if="worksites.isEmpty()">
                <div class="flex justify-center mb-4">
                    <v-spa-lottie-empty class="max-w-[300px]"></v-spa-lottie-empty>
                </div>
                <div class="text-center">
                    <h1 class="mb-2 text-3xl font-thin text-gray-800">{{ $t('worksites.index.empty.title') }}</h1>
                    <p class="mb-8 text-sm text-gray-500">{{ $t('worksites.index.empty.text') }}</p>
                    <div class="flex justify-center">
                        <v-spa-loading-button @clicked="$bus.$emit('worksite:worksite-form-modal:open')">
                            {{ $t('worksites.index.empty.button') }}
                        </v-spa-loading-button>
                    </div>
                </div>
            </template> 
            <div class="flex flex-col space-y-8" v-else>
                <div class="mb-12" v-if="currentWorksitesWithoutFilters.length > 0">
                    <div class="flex items-center justify-between mb-4">
                        <h2 class="text-2xl text-medium">{{ $t('worksites.index.current.title') }}</h2>
                        <div class="flex items-center space-x-4">
                            <input v-model="currentWorksitesFilters.search" type="search" :placeholder="$t('worksites.index.filters.search_placeholder')" :class="`${$spa.css.form.input} min-w-[250px]`" />
                            <select v-model="currentWorksitesFilters.sort" :class="$spa.css.form.select">
                                <option value="starts_at">{{ $t('worksites.index.filters.sort_by_starts_at') }}</option>
                                <option value="ends_at">{{ $t('worksites.index.filters.sort_by_ends_at') }}</option>
                                <option value="title_asc">{{ $t('worksites.index.filters.sort_by_title_asc') }}</option>
                            </select>
                        </div>
                    </div>
                    <worksites-index-list :worksites="currentWorksites" v-if="currentWorksites.length > 0"></worksites-index-list>
                </div>

                <div v-if="futureWorksitesWithoutFilters.length > 0">
                    <div class="flex items-center justify-between mb-4">
                        <h2 class="text-2xl text-medium">{{ $t('worksites.index.future.title') }}</h2>
                        <div class="flex items-center space-x-4">
                            <input v-model="futureWorksitesFilters.search" type="search" :placeholder="$t('worksites.index.filters.search_placeholder')" :class="`${$spa.css.form.input} min-w-[250px]`" />
                            <select v-model="futureWorksitesFilters.sort" :class="$spa.css.form.select">
                                <option value="starts_at">{{ $t('worksites.index.filters.sort_by_starts_at') }}</option>
                                <option value="ends_at">{{ $t('worksites.index.filters.sort_by_ends_at') }}</option>
                                <option value="title_asc">{{ $t('worksites.index.filters.sort_by_title_asc') }}</option>
                            </select>
                        </div>
                    </div>
                    <worksites-index-list :worksites="futureWorksites" v-if="futureWorksites.length > 0"></worksites-index-list>
                </div>
            </div>
        </template>
    </div>
    
</template>

<script>
    import ActiveWorksiteCollection from '~spa/apps/trustup-pro-worksite-js/collections/ActiveWorksiteCollection';
    import WorksitesIndexList from './Index/_List';

    export default {
        components: {
            WorksitesIndexList
        },
        data() {
            return {
                worksites: [],
                currentWorksitesFilters: {
                    search: '',
                    sort: 'starts_at',
                },
                futureWorksitesFilters: {
                    search: '',
                    sort: 'starts_at',
                },
                loading: true
            }
        },
        computed: {
            currentWorksitesWithoutFilters()
            {
                return this.worksites.filter(w => w.isCurrent());
            },
            currentWorksites()
            {
                return this.currentWorksitesWithoutFilters
                    .filter(w => w.title.toLowerCase().includes(this.currentWorksitesFilters.search.toLowerCase()))
                    .sort((a, b) => {
                        if ( this.currentWorksitesFilters.sort === 'starts_at' ) {
                            return new Date(a.starts_at) - new Date(b.starts_at);
                        }
                        if ( this.currentWorksitesFilters.sort === 'ends_at' ) {
                            return new Date(b.ends_at) - new Date(a.ends_at);
                        }
                        if ( this.currentWorksitesFilters.sort === 'title_asc' ) {
                            return a.title.localeCompare(b.title);
                        }
                    });
            },
            futureWorksitesWithoutFilters()
            {
                return this.worksites.filter(w => w.isFuture());
            },
            futureWorksites()
            {
                return this.futureWorksitesWithoutFilters
                    .filter(w => w.title.toLowerCase().includes(this.futureWorksitesFilters.search.toLowerCase()))
                    .sort((a, b) => {
                        if ( this.futureWorksitesFilters.sort === 'starts_at' ) {
                            return new Date(a.starts_at) - new Date(b.starts_at);
                        }
                        if ( this.futureWorksitesFilters.sort === 'ends_at' ) {
                            return new Date(b.ends_at) - new Date(a.ends_at);
                        }
                        if ( this.futureWorksitesFilters.sort === 'title_asc' ) {
                            return a.title.localeCompare(b.title);
                        }
                    });
            }
        },
        methods: {
            async getWorksites()
            {
                let {worksites} = await window.Worksite.endpoints.worksite.index();
                this.worksites = worksites;
                this.loading = false;
            },
        },
        async created() {
            await this.getWorksites();
        }
    }
</script>