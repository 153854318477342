<template>
    <div class="relative pb-12">

        <v-spa-loader v-if="loading" />

        <!-- Amounts -->
        <profitability-amounts-panels ref="amountsPanels"></profitability-amounts-panels>

        <!-- Graph -->
        <div class="relative mb-6 pt-[20px]">

            <!-- Graph columns and headers -->
            <div class="absolute inset-0 flex pointer-events-none ">
                <profitability-graph-sidebar>
                    <div class="absolute right-0 w-[1px] bg-gray-300 top-0 bottom-0"></div>
                    <div class="absolute top-0 right-0 -mt-8" style="transform: translateX(100%)">
                        <span class="text-sm text-gray-400">{{ 0 | price }}</span>
                    </div>
                </profitability-graph-sidebar>
                <profitability-graph-content>
                    <div class="absolute top-0 right-[50%] -mt-8" style="transform: translateX(calc(50% + 5px))">
                        <span class="text-sm text-gray-400">{{ (worksite.getProfitability().total.quoted / 2) | price }}</span>
                    </div>
                    <div class="absolute top-0 right-0 -mt-8">
                        <span class="text-sm text-gray-400">{{ worksite.getProfitability().total.quoted | price }}</span>
                    </div>
                    <div class="absolute left-[50%] w-[1px] bg-gray-300 top-0 bottom-0"></div>
                    <div class="absolute right-0 w-[1px] bg-gray-300 top-0 bottom-0"></div>
                </profitability-graph-content>
            </div>

            <profitability-graph-section>
                <profitability-graph-sidebar>
                    <profitability-graph-sidebar-item @clicked="$bus.$emit('worksite:worksite-profitability-document-form-modal:open', {worksite: $store.state.worksite, type: 'quote'})">
                        {{ $t('worksite.profitability.grid.labels.quotes') }} <i class="ml-2 text-sm far fa-plus-circle text-primary hover:text-primary-dark" />
                    </profitability-graph-sidebar-item> 
                </profitability-graph-sidebar>
                <profitability-graph-content>
                    <profitability-graph-inner-content>
                        <profitability-graph-item
                            v-for="quote in worksite.getQuotes()"
                            :key="quote.uuid"
                            :width="worksite.chartWidth(quote)"
                            :text="quote.number"
                            color="bg-profitability-quote"
                            @contextmenu="quote.contextMenu($event)"
                            @clicked="quote.preview()"
                        >
                        </profitability-graph-item>
                    </profitability-graph-inner-content>
                </profitability-graph-content>
            </profitability-graph-section>

            <profitability-graph-section>
                <profitability-graph-sidebar>
                    <profitability-graph-sidebar-item @clicked="$bus.$emit('worksite:worksite-profitability-document-form-modal:open', {worksite: $store.state.worksite, type: 'invoice'})">
                        {{ $t('worksite.profitability.grid.labels.invoices') }} <i class="ml-2 text-sm far fa-plus-circle text-primary hover:text-primary-dark" />
                    </profitability-graph-sidebar-item> 
                </profitability-graph-sidebar>
                <profitability-graph-content
                    :remaining-text="$t('worksite.profitability.grid.content.remaining_to_invoice')"
                    :remaining-sub-text="worksite.getProfitability().total.remaining_to_invoice | price"
                    :remaining-width="worksite.chartRemainingInvoiceWidth()"
                >
                    <profitability-graph-inner-content>
                        <profitability-graph-item
                            v-for="invoice in worksite.getInvoices()"
                            :key="invoice.uuid"
                            :width="worksite.chartWidth(invoice)"
                            :text="invoice.number"
                            :color="worksite.chartColor(invoice)"
                            @contextmenu="invoice.contextMenu($event)"
                            @clicked="invoice.preview()"
                        >
                        </profitability-graph-item>
                    </profitability-graph-inner-content>
                </profitability-graph-content>
            </profitability-graph-section>

            <profitability-graph-section>
                <profitability-graph-sidebar>
                    <profitability-graph-sidebar-item  @clicked="$bus.$emit('worksite:worksite-profitability-document-form-modal:open', {worksite: $store.state.worksite, type: 'cost'})">
                        {{ $t('worksite.profitability.grid.labels.costs') }} <i class="ml-2 text-sm far fa-plus-circle text-primary hover:text-primary-dark" />
                    </profitability-graph-sidebar-item> 
                    <div class="-mt-4">
                        <profitability-graph-sidebar-item :small="true">
                            {{ $t('worksite.profitability.grid.labels.purchase_invoices') }}
                        </profitability-graph-sidebar-item>
                        <profitability-graph-sidebar-item :small="true">
                            {{ $t('worksite.profitability.grid.labels.labor') }}
                        </profitability-graph-sidebar-item>
                        <profitability-graph-sidebar-item :small="true">
                            {{ $t('worksite.profitability.grid.labels.subcontracting') }}
                        </profitability-graph-sidebar-item>
                        <profitability-graph-sidebar-item :small="true">
                            {{ $t('worksite.profitability.grid.labels.other_costs') }}
                        </profitability-graph-sidebar-item>
                    </div>
                </profitability-graph-sidebar>
                <profitability-graph-content
                    :remaining-text="$t('worksite.profitability.grid.content.margin')"
                    :remaining-sub-text="worksite.getProfitability().total.margin | price"
                    :remaining-width="worksite.chartMarginWidth()"
                >
                    <div class="w-full">
                        <profitability-graph-inner-content>
                            <profitability-graph-fake-item></profitability-graph-fake-item>
                        </profitability-graph-inner-content>

                        <div class="-mt-4">
                            <profitability-graph-inner-content :small="true">
                                <profitability-graph-fake-item :width="0"></profitability-graph-fake-item>
                                <profitability-graph-item
                                    v-for="cost in worksite.getCostsOfType('purchase-invoice')"
                                    :key="cost.uuid"
                                    :width="worksite.chartWidth(cost)"
                                    :text="cost.number"
                                    :display-text="false"
                                    color="bg-gray-400"
                                    @contextmenu="cost.contextMenu($event)"
                                    @clicked="cost.preview()"
                                >
                                </profitability-graph-item>
                            </profitability-graph-inner-content>
                            <profitability-graph-inner-content :small="true">
                                <profitability-graph-fake-item :width="worksite.chartWidthMultipleSubCosts(['purchase-invoice'])"></profitability-graph-fake-item>
                                <profitability-graph-item
                                    v-for="cost in worksite.getCostsOfType('labor')"
                                    :key="cost.uuid"
                                    :width="worksite.chartWidth(cost)"
                                    :text="cost.number"
                                    :display-text="false"
                                    color="bg-gray-400"
                                    @contextmenu="cost.contextMenu($event)"
                                    @clicked="cost.preview()"
                                >
                                </profitability-graph-item>
                            </profitability-graph-inner-content>
                            <profitability-graph-inner-content :small="true">
                                <profitability-graph-fake-item :width="worksite.chartWidthMultipleSubCosts(['purchase-invoice', 'labor'])"></profitability-graph-fake-item>
                                <profitability-graph-item
                                    v-for="cost in worksite.getCostsOfType('subcontracting')"
                                    :key="cost.uuid"
                                    :width="worksite.chartWidth(cost)"
                                    :text="cost.number"
                                    :display-text="false"
                                    color="bg-gray-400"
                                    @contextmenu="cost.contextMenu($event)"
                                    @clicked="cost.preview()"
                                >
                                </profitability-graph-item>
                            </profitability-graph-inner-content>
                            <profitability-graph-inner-content :small="true">
                                <profitability-graph-fake-item :width="worksite.chartWidthMultipleSubCosts(['purchase-invoice', 'labor', 'subcontracting'])"></profitability-graph-fake-item>
                                <profitability-graph-item
                                    v-for="cost in worksite.getCostsOfType('other-cost')"
                                    :key="cost.uuid"
                                    :width="worksite.chartWidth(cost)"
                                    :text="cost.number"
                                    :display-text="false"
                                    color="bg-gray-400"
                                    @contextmenu="cost.contextMenu($event)"
                                    @clicked="cost.preview()"
                                >
                                </profitability-graph-item>
                            </profitability-graph-inner-content>
                        </div>
                    </div>
                </profitability-graph-content>
            </profitability-graph-section>
        </div>

        <p class="mb-4 text-sm text-gray-500">{{ $t('worksite.profitability.grid.bottom_text') }}</p>

        <a href="#" @click.prevent="refreshDocuments" class="text-xs text-gray-500 transition-all hover:text-black">{{ $t('worksite.profitability.grid.refresh') }}</a>

        <iframe id="download-document-iframe" style="display:none;"></iframe>
    </div>
</template>

<script>
    import {mapState} from 'vuex';
    import ProfitabilityAmountsPanel from './Profitability/AmountsPanels';
    import GraphSection from './Profitability/Graph/Section';
    import GraphSidebar from './Profitability/Graph/Sidebar';
    import GraphSidebarItem from './Profitability/Graph/SidebarItem';
    import GraphContent from './Profitability/Graph/Content';
    import GraphInnerContent from './Profitability/Graph/InnerContent';
    import GraphItem from './Profitability/Graph/Item';
    import GraphFakeItem from './Profitability/Graph/FakeItem';

    export default {
        components: {
            'profitability-amounts-panels': ProfitabilityAmountsPanel,
            'profitability-graph-section': GraphSection,
            'profitability-graph-sidebar': GraphSidebar,
            'profitability-graph-sidebar-item': GraphSidebarItem,
            'profitability-graph-content': GraphContent,
            'profitability-graph-inner-content': GraphInnerContent,
            'profitability-graph-item': GraphItem,
            'profitability-graph-fake-item': GraphFakeItem,
        },

        data() {
            return {
                currentDocument: null,
                loading: false
            }
        },
        computed: {
            ...mapState(['worksite']),
        },

        methods: {
            refreshProfitabilityPanels()
            {
                if ( ! this.$refs.amountsPanels ) {
                    return;
                }
                
                this.$refs.amountsPanels.$forceUpdate();
            },

            refresh()
            {
                console.log('test');
                setTimeout(() => {
                    console.log('test2');
                    this.worksite.refreshProfitability();
                    this.refreshProfitabilityPanels();
                }, 100);
            },

            async refreshDocuments()
            {
                this.loading = true;

                try {

                    let response = await window.Worksite.endpoints.worksiteProfitabilityDocument.setWorksite(this.$store.state.worksite).refresh();
                    if ( response.refreshed && response.refreshed.every((r) => r.status == true) ) {
                        window.Toasteo.success( this.$t('worksite.profitability.grid.refresh_success') );
                    }

                } catch (e) {

                    console.log(e);
                    this.loading = false;
                    window.Toasteo.error( this.$t('worksite.profitability.grid.refresh_error') );
                    return;
                    
                }

                let worksite = await window.Worksite.endpoints.worksite.retrieve(this.$store.state.worksite.uuid, {tasks: true});
                await worksite.getFullContact();
                this.$store.commit('setWorksite', worksite);
                
                this.loading = false;
            }
        },

        created()
        {
            this.$bus.$on('worksite:worksite-profitability-document:created', this.refresh);
            this.$bus.$on('worksite:worksite-profitability-document:updated', this.refresh);
            this.$bus.$on('worksite:worksite-profitability-document:deleted', this.refresh);
            this.$bus.$on('worksite:worksite:updated', this.refresh);
        },

        beforeDestroy()
        {
            this.$bus.$off('worksite:worksite-profitability-document:created', this.refresh);
            this.$bus.$off('worksite:worksite-profitability-document:updated', this.refresh);
            this.$bus.$off('worksite:worksite-profitability-document:deleted', this.refresh);
            this.$bus.$off('worksite:worksite:updated', this.refresh);
        }
    }
</script>