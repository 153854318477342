import i18n from "~base/_i18n";

export default {

    /**
     * Set the current user.
     *
     * @param {Object} state Vuex's state
     * @param {Object} user
     */
    setUser(state, user) {
        state.user = user;
        
        if ( user && window.Intercom ) {
            window.intercomSettings.user_id = user.id;
            window.intercomSettings.user_hash = user.intercom_hash;
            window.intercomSettings.name = user.first_name + ' ' + user.last_name;
            window.intercomSettings.email = user.email;
            window.intercomSettings.created_at = user.created_at;
            window.Intercom('update', window.intercomSettings); 
        }
    },

    /**
     * Set the current token.
     *
     * @param {Object} state Vuex's state
     * @param {Object} token
     */
    setToken(state, token) {
        state.token = token;
        window.axios.defaults.headers.common['Authorization'] = state.token.token_type + ' ' + state.token.access_token;
    },

    /**
     * Remove the token from the localstorage.
     *
     * @param {Object} state Vuex's state
     */
    removeToken(state)
    {
        state.token = null;
        window.axios.defaults.headers.common['Authorization'] = null;
    },

    /**
     * Set the account.
     *
     * @param {Object} state Vuex's state
     * @param {Object} account
     */
     setAccount(state, account) {
        state.account = account;

        if ( account && window.Intercom ) {
            window.intercomSettings.tasks_account = account.name;
            window.intercomSettings.tasks_account_uuid = account.uuid;
            window.intercomSettings.tasks_account_created_at = account.created_at;
            window.Intercom('update', window.intercomSettings);
        }
    },

    /**
     * Set the sidebar section of the app.
     * 
     * @param {Object} state Vuex's state
     * @param {String} section
     */
    setSidebarSection(state, section) {
        state.sidebarSection = section;
    },

    /**
     * Set the status of the application loading.
     * 
     * @param {Object}  state Vuex's state
     * @param {Boolean} status
     */
    setApplicationLoadingStatus(state, status) {
        state.loadingApplication = status;
    },

    setLocale(state, locale) {
        console.log('Setting user locale', locale);
        state.locale = locale;
        i18n.locale = locale;
        window.moment.locale(locale.split('-').pop());
        if ( window.moment.locale() == 'be' ) {
            window.moment.locale('fr');
        }
        console.log('Setting moment locale', locale, locale.split('-').pop(), window.moment.locale());
    },

    /**
     * Set the google maps instance.
     * 
     * @param {Object} state Vuex's state
     * @param {Object} google
     */
    setGoogle(state, google) {
        state.google = google;
    },

    /**
     * Set the worksite.
     *
     * @param {Object} state Vuex's state
     * @param {Object} worksite
     */
     setWorksite(state, worksite) {
        if ( state.worksite && typeof state.worksite.isModel === 'function' && state.worksite.isModel()) {
            state.worksite.destroy();
        }

        state.worksite = worksite;
    },

    /**
     * Set the worksites.
     *
     * @param {Object} state Vuex's state
     * @param {Array} worksites
     */
     setWorksites(state, worksites) {
        if ( state.models.worksites && typeof state.models.worksites.isCollection === 'function' && state.models.worksites.isCollection()) {
            state.models.worksites.destroy();
        }

        state.models.worksites = worksites;
    },

    /**
     * Set the worksite's phases.
     *
     * @param {Object} state Vuex's state
     * @param {Array} phases
     */
     setWorksitePhases(state, phases) {
        if ( state.models.worksitePhases && typeof state.models.worksitePhases.isCollection === 'function' && state.models.worksitePhases.isCollection()) {
            state.models.worksitePhases.destroy();
        }

        state.worksitePhases = phases;
    },

    /**
     * Set the users.
     *
     * @param {Object} state Vuex's state
     * @param {Array} users
     */
     setUsers(state, users) {
        state.models.users = users;
    },

    /**
     * Set the opportunity.
     *
     * @param {Object} state Vuex's state
     * @param {Object} opportunity
     */
     setOpportunity(state, opportunity) {
        if ( state.opportunity && typeof state.opportunity.isModel === 'function' && state.opportunity.isModel()) {
            state.opportunity.destroy();
        }

        state.opportunity = opportunity;
    },

    /**
     * Set the opportunity's quotes.
     *
     * @param {Object} state Vuex's state
     * @param {Object} quotes
     */
     setOpportunityQuotes(state, quotes) {
        if ( state.models.opportunityQuotes && typeof state.models.opportunityQuotes.isCollection === 'function' && state.models.opportunityQuotes.isCollection()) {
            state.models.opportunityQuotes.destroy();
        }

        state.opportunityQuotes = quotes;
    },
    
    addOpenedModal(state, modal) {
        state.openedModals.unshift(modal);
    },

    moveOpenedModalToTop(state, modal) {
        let index = state.openedModals.indexOf(modal);
        console.log(index);

        if ( index > 0 ) {
            state.openedModals.splice(index, 1);
            state.openedModals.unshift(modal);
        }
    },

    removeOpenedModal(state, modal) {
        let index = state.openedModals.indexOf(modal);
        if ( index > -1 ) {
            state.openedModals.splice(index, 1);
        }
    }
};